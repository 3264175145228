<template>
    <KModal
        :show.sync="showModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Component Settings Dialog"
        color="info"
    >
        <template #header>
            <h6 class="modal-title text-center">펌웨어 업로드</h6>
        </template>

        <div class='modal-body'>
            <form>
                <input type="file" accept=".py, .txt" @change="onFileChange" id="fileInput"/>
                <!-- <button @click="sendFirmwareToDevice()">버튼</button> -->
            </form>
        </div>
        <!-- <CButton @click="sendFirmwareToDevice()" color="info">업로드</CButton> -->

        <template #footer>
            <CButton @click="register()" color="info">저장</CButton>
            <CButton @click="cancel()" color="light">취소</CButton>
        </template>

        <Notify ref="notifyDialog"/>

    </KModal>
</template>

<script>
import Uploader from '@/views/device/Uploader'

export default {
    // extends: Uploader,
    props: {
        device_guid: String
    },
    data() {
        return {
            showModal: false,
            fileName: '',
            fileContent: '',
            file: []
        }
    },
    methods: {
        async onFileChange(event) {
            this.file = event.target.files[0]
            const file = event.target.files[0]

            this.fileName = file.name

            //console.log("fileName : ", this.fileName)

            // file.type === 'text/plain'
            if(file) {
                try {
                    this.fileContent = await this.readFile(file)
                    console.log(this.fileContent)
                } catch {
                    console.error('파일 읽기 실패 : ', err)
                }
            } else {
                alert("파일을 선택해주세요")
            }
        },
        readFile(file) {
            return new Promise((res, rej) => {
                const reader = new FileReader()

                reader.onload = (e) => {
                    res(e.target.result)
                }

                reader.onerror = (e) => {
                    rej(e)
                }
                reader.readAsText(file);
            })
        },
        sendFirmwareToDevice() {
            var data = {
                command: 'exec',
                device_guid: this.device_guid,
                params: [{
                    cmd: 'put',
                    data: {
                        did: this.device_guid,
                        name: "/home/pi/datalogger/"+this.fileName,
                        source: this.fileContent
                    }
                }]
            }
            //console.log("업로드 Data : ", data)
            this.$store.dispatch('rest/method', { model: 'devices', method: 'Command', data: data})
            .then(res => {
                console.log("error : ", err.toString())
                var message = err.toString();
                if(message === "Error: Already running command: run after a minutes"){
                    message = "이미 처리중인 작업이 있습니다. 잠시만 기다려주세요.";
                }
                this.$refs.notifyDialog.show(message.toString())
            })
            
        },
        cancel() {
            const fileInput = document.getElementById('fileInput');
            fileInput.value = "";
            this.fileName = ''
            this.showModal = false
        },
        register() {
            this.sendFirmwareToDevice()
            this.showModal = false
        },
        show() {
            this.showModal = true;
        },
    }

}
</script>

<style>

</style>